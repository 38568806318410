<template>
  <div
    ref="wrapper"
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: [0, 0.5, 1.0],
      },
    }"
  ></div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true,
      default: "",
    },
    autoPlay: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isIntersecting: false,
      video: null,
      videoIsReady: false,
    };
  },
  mounted() {
    const _wq = (window._wq = window._wq || []);
    let context = this;
    _wq.push({
      id: this.videoId,
      options: {
        autoPlay: false,
        // silentAutoPlay: "allow",
        videoFoam: true,
        seo: false,
        playerColor: "4188EC",
        plugin: {
          "captions-v1": {
            onByDefault: true,
          },
        },
      },
      onReady: function (video) {
        // video.bind('play', function() {
        // // event handler
        // });

        // video.bind('pause', function() {
        //   // event handler
        // });
        context.videoIsReady = true;
        context.video = video;
      },
    });
    const script1 = document.createElement("script");
    script1.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script1.async = true;
    const div = document.createElement("div");
    div.innerHTML = `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_${this.videoId}" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/${this.videoId}/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>`;
    const container = this.$refs.wrapper;
    container.appendChild(script1);
    container.appendChild(div);
  },
  methods: {
    onIntersect(entries) {
      if (!this.videoIsReady || !this.autoPlay) {
        return;
      }

      //  https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].intersectionRatio >= 0.5;
      if (this.isIntersecting) {
        this.video.play();
      } else {
        this.video.pause();
      }
    },
  },
};
</script>
